<template>
    <div>
        <div class="card" v-for="provement in provements" :key="provement.id">
            <div class="file-title">{{ provement.title }}</div>
            <div class="file-container">
                <a v-for="file in provement.files" :key="file.id" target="_blank" :href="fileUrl(file.file)" class="file">
                    {{splitFileName(file.name)}}
                </a>
                <div class="varified" v-if="provement.varified">
                    已獲機構認證
                </div>
            </div>
            <div class="description-title">簡介</div>
            <div class="description">{{ provement.description }}</div>
            <div class="date">{{ provement.date }}</div>
        </div>
    </div>
</template>

<script>
    import api from "@/api";

export default {
    data() {
        return {
            provements: [
                {
                    id: 1,
                    title: "醫療報告",
                    files: [],
                    varified: true,
                    description: undefined,
                    date: undefined,
                },
                {
                    id: 2,
                    title: "聲明書",
                    files: [],
                    varified: true,
                    description: undefined,
                    date: undefined,
                },
            ],
        };
    },
    created:function (){
        let id = this.$route.params.id;
        api.fundraise.getFundraiseDetails(id).then(response => {
            this.provements[0].files =response.data.personal.relatedMedicalFiles
            this.provements[0].description =response.data.personal.introductionMedicalReport
            this.provements[1].files =response.data.personal.otherFiles
            this.provements[1].description =response.data.personal.introductionStatement
            this.provements[0].date =this.provements[1].date = this.times(response.data.personal.createDatetime)
        }).catch(error=>{
            this.$message.error('基本資料數據異常，請重新獲取！');
        });
    },
    methods: {
        fileUrl(file) {
            return process.env.VUE_APP_SERVER +file;
        },
        times(time){
            var date = new Date(time);
            var y = date.getFullYear();
            var m = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
            var d = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
            return y+'年'+m+'月'+d+'日';
        },
        splitFileName(file_name) {
            if(file_name) {
                let file_names = file_name.split('.')
                return file_names[0]
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.card {
    background-color: $green-100;
    margin-bottom: 16px;
    padding: 16px;
    display: grid;
    grid-template-columns: 10vw 1fr;
    grid-template-rows: auto auto auto;
    gap: 8px;
    line-height: 1.2;
    .file-title,
    .description-title {
        font-weight: 600;
    }
    .file-container {
        @include fc-s-s;
    }
    .file {
        margin-bottom: 4px;
    }
    .varified {
        color: $green-500;
        @include fr-s-c;
        font-size: 14px;
        &::before {
            flex-shrink: 0;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            margin-right: 4px;
            display: block;
            background-color: $green-500;
            content: "";
        }
    }
    .date {
        grid-column: 1 / 3;
        text-align: right;
    }
}
@media screen and (max-width: 900px) {
    .card {
        grid-template-columns: 70px 1fr;
        grid-template-rows: auto auto;
    }
    .description-title,
    .description {
        display: none;
    }
}
</style>